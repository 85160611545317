import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  RECURRENT_PAYMENTS_GET_LIST_PROGRESS,
  RECURRENT_PAYMENTS_GET_LIST_SUCCESS,
  RECURRENT_PAYMENTS_GET_LIST_FAILED,
  RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_PROGRESS,
  RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_SUCCESS,
  RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_FAILED,
  RECURRENT_PAYMENTS_CLEAR_STATE,
} from '../actions/RecurrentPaymentsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECURRENT_PAYMENTS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: PROGRESS },
        },
      );
    }
    case RECURRENT_PAYMENTS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: SUCCESS },
        },
      );
    }
    case RECURRENT_PAYMENTS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAILED },
        },
      );
    }
    case RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case RECURRENT_PAYMENTS_GET_RECURRENT_PAYMENT_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case RECURRENT_PAYMENTS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
