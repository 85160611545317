import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";

const MERCHANTS_GET_LIST_PROGRESS = 'MERCHANTS_GET_LIST_PROGRESS';
const MERCHANTS_GET_LIST_SUCCESS = 'MERCHANTS_GET_LIST_SUCCESS';
const MERCHANTS_GET_LIST_FAILED = 'MERCHANTS_GET_LIST_FAILED';
const MERCHANTS_FORM_SUBMIT_PROGRESS = 'MERCHANTS_FORM_SUBMIT_PROGRESS';
const MERCHANTS_FORM_SUBMIT_SUCCESS = 'MERCHANTS_FORM_SUBMIT_SUCCESS';
const MERCHANTS_FORM_SUBMIT_FAILED = 'MERCHANTS_FORM_SUBMIT_FAILED';
const MERCHANTS_GET_MERCHANT_PROGRESS = 'MERCHANTS_GET_MERCHANT_PROGRESS';
const MERCHANTS_GET_MERCHANT_SUCCESS = 'MERCHANTS_GET_MERCHANT_SUCCESS';
const MERCHANTS_GET_MERCHANT_FAILED = 'MERCHANTS_GET_MERCHANT_FAILED';
const MERCHANTS_CLEAR_STATE = 'MERCHANTS_CLEAR_STATE';

const merchantGetListProgress = () => ({
  type: MERCHANTS_GET_LIST_PROGRESS,
});

const merchantGetListSuccess = payload => ({
  type: MERCHANTS_GET_LIST_SUCCESS,
  payload,
});

const merchantGetListFailed = () => ({
  type: MERCHANTS_GET_LIST_FAILED,
});

const merchantStartFormSubmit = () => ({
  type: MERCHANTS_FORM_SUBMIT_PROGRESS,
});

const merchantFormSubmitSuccess = () => ({
  type: MERCHANTS_FORM_SUBMIT_SUCCESS,
});

const merchantFormSubmitFailed = () => ({
  type: MERCHANTS_FORM_SUBMIT_FAILED,
});

const merchantGetFormProgress = () => ({
  type: MERCHANTS_GET_MERCHANT_PROGRESS,
});

const merchantGetFormSuccess = payload => ({
  type: MERCHANTS_GET_MERCHANT_SUCCESS,
  payload,
});

const merchantGetFormFailed = () => ({
  type: MERCHANTS_GET_MERCHANT_FAILED,
});

const merchantClearState = () => ({
  type: MERCHANTS_CLEAR_STATE,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    merchantId: doc.merchantId,
    isArchive: doc.isArchive,
    updatedAt: moment(doc.updatedAt).format("LLL"),
  }));

  return { list, pagination };
};

const getMerchantsList = params => dispatch => {
  const token = getToken();
  dispatch(merchantGetListProgress());

  ws.emit('api/app/merchants/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(merchantGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(merchantGetListFailed());
    }
  });
};

const convertSetMerchantToDB = (data) => {
  const { name, merchantId, secretKey } = data;

  return {
    name,
    merchantId,
    secretKey,
  };
};

const createMerchant = (data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetMerchantToDB(data);

  dispatch(merchantStartFormSubmit());

  ws.emit('api/app/merchants/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(merchantFormSubmitSuccess());
    } else {
      dispatch(merchantFormSubmitFailed());
    }
  });
};

const convertDBMerchantToEdit = payload => {
  const { merchant: merchantFromDB } = payload;
  const {
    name, merchantId, secretKey, isArchive, created, modified, createdAt, updatedAt,
  } = merchantFromDB;

  const merchant = {
    name,
    merchantId,
    secretKey,
  };

  const statistic = {
    created: `${created.name} ${created.surname}`,
    createdAt: moment(createdAt).format("LLL"),
    updated: `${modified.name} ${modified.surname}`,
    updatedAt: moment(updatedAt).format("LLL"),
    isArchive,
  };

  return { merchant, statistic };
};

const getMerchant = merchantId => dispatch => {
  const token = getToken();
  dispatch(merchantGetFormProgress());

  ws.emit('api/app/merchants/getById', { token, payload: { merchantId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(merchantGetFormSuccess(convertDBMerchantToEdit(payload)));
    } else {
      dispatch(merchantGetFormFailed());
    }
  });
};

const updateMerchant = (id, data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetMerchantToDB(data);

  dispatch(merchantStartFormSubmit());

  ws.emit('api/app/merchants/updateById', { token, payload: { merchantId: id, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(merchantFormSubmitSuccess());
    } else {
      dispatch(merchantFormSubmitFailed());
    }
  });
};

export {
  MERCHANTS_GET_LIST_PROGRESS,
  MERCHANTS_GET_LIST_SUCCESS,
  MERCHANTS_GET_LIST_FAILED,
  MERCHANTS_FORM_SUBMIT_PROGRESS,
  MERCHANTS_FORM_SUBMIT_SUCCESS,
  MERCHANTS_FORM_SUBMIT_FAILED,
  MERCHANTS_GET_MERCHANT_PROGRESS,
  MERCHANTS_GET_MERCHANT_SUCCESS,
  MERCHANTS_GET_MERCHANT_FAILED,
  MERCHANTS_CLEAR_STATE,
  merchantClearState,
  getMerchantsList,
  createMerchant,
  getMerchant,
  updateMerchant,
};
