import io from "socket.io-client";
import config from "./config/config";
import store from "./config/configureStore";
import {
  authStartTokenRenewSubmit,
  authTokenRenewSubmitSuccess,
  authTokenRenewSubmitFailed,
  getExpiresInDate,
} from "./actions/AuthAction";

const socket = io(config.urls.api);
const { emit } = socket;

// ===================================

let isNowRefreshToken = false;
let deferredArguments = [];

socket.emit = function (event) {
  const now = Date.now();
  const { auth } = store.getState();
  const { accessExp, refreshExp, tokens } = auth;

  const refreshToken = () => new Promise((resolve, reject) => {
    store.dispatch(authStartTokenRenewSubmit());

    const args = [
      'api/app/auth/renewToken', // Имя события для обновления пары токенов
      { token: tokens.refreshToken }, // Объект с refreshToken
      (result) => { // Функция обработчик новых токенов, которые вернул API
        const { status, payload } = result;
        // console.log(result);
        // Если API вернул новую пару токенов
        if (status === 'ok') {
          // Сохраняем их в Redux и localStorage
          // store.dispatch(authTokenRenewSubmitSuccess(getExpiresInDate(payload)));
          resolve(payload);
        } else {
          // store.dispatch(authTokenRenewSubmitFailed());
          reject();
        }
      },
    ];

    emit.apply(socket, args);
  });

  if (arguments[1].hasOwnProperty('token') && now > (accessExp - 60000)) {
    if (now < refreshExp) {
      deferredArguments.push(arguments);

      if (!isNowRefreshToken) {
        isNowRefreshToken = true;

        refreshToken()
          .then((payload) => {
            console.log('update token');
            return store.dispatch(authTokenRenewSubmitSuccess(getExpiresInDate(payload)));
          })
          .then(() => {
            const { auth: updatedAuth } = store.getState();
            const { tokens: updatedTokens } = updatedAuth;
            const { accessToken } = updatedTokens;

            for (const deferredArgument of deferredArguments) {
              deferredArgument[1].token = accessToken;
              emit.apply(socket, deferredArgument);
            }
          })
          .catch(() => {
            console.log('cancel');
            store.dispatch(authTokenRenewSubmitFailed());
          })
          .finally(() => {
            isNowRefreshToken = false;
            deferredArguments = [];
          });
      }
    } else {
      store.dispatch(authTokenRenewSubmitFailed());
    }
  } else {
    emit.apply(socket, arguments);
  }
}

export default socket;
