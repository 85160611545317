import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  SUBSCRIPTIONS_GET_LIST_PROGRESS,
  SUBSCRIPTIONS_GET_LIST_SUCCESS,
  SUBSCRIPTIONS_GET_LIST_FAILED,
  SUBSCRIPTIONS_FORM_SUBMIT_PROGRESS,
  SUBSCRIPTIONS_FORM_SUBMIT_SUCCESS,
  SUBSCRIPTIONS_FORM_SUBMIT_FAILED,
  SUBSCRIPTIONS_GET_SUBSCRIPTION_PROGRESS,
  SUBSCRIPTIONS_GET_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_GET_SUBSCRIPTION_FAILED,
  SUBSCRIPTIONS_GET_CODE_PROGRESS,
  SUBSCRIPTIONS_GET_CODE_SUCCESS,
  SUBSCRIPTIONS_GET_CODE_FAILED,
  SUBSCRIPTIONS_GET_ASYNC_DATA_PROGRESS,
  SUBSCRIPTIONS_GET_ASYNC_DATA_SUCCESS,
  SUBSCRIPTIONS_GET_ASYNC_DATA_FAILED,
  SUBSCRIPTIONS_CLEAR_STATE,
  SUBSCRIPTIONS_DUPLICATE_SUBMIT_PROGRESS,
  SUBSCRIPTIONS_DUPLICATE_SUBMIT_SUCCESS,
  SUBSCRIPTIONS_DUPLICATE_SUBMIT_FAILED,
} from '../actions/SubscriptionsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  loadCodeStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  duplicateStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  statistic: {},
  asyncData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTIONS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: PROGRESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: SUCCESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAILED },
        },
      );
    }
    case SUBSCRIPTIONS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: PROGRESS } },
      );
    }
    case SUBSCRIPTIONS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          submitStatus: { $set: SUCCESS },
        },
      );
    }
    case SUBSCRIPTIONS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: FAILED } },
      );
    }
    case SUBSCRIPTIONS_GET_SUBSCRIPTION_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_SUBSCRIPTION_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.subscription },
          statistic: { $set: action.payload.statistic || {} },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_SUBSCRIPTION_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case SUBSCRIPTIONS_GET_CODE_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadCodeStatus: { $set: PROGRESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_CODE_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.subscription },
          statistic: { $set: action.payload.statistic || {} },
          loadCodeStatus: { $set: SUCCESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_CODE_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadCodeStatus: { $set: FAILED },
        },
      );
    }
    case SUBSCRIPTIONS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: PROGRESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: SUCCESS },
        },
      );
    }
    case SUBSCRIPTIONS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: FAILED },
        },
      );
    }
    case SUBSCRIPTIONS_DUPLICATE_SUBMIT_PROGRESS: {
      return update(
        state,
        { duplicateStatus: { $set: PROGRESS } },
      );
    }
    case SUBSCRIPTIONS_DUPLICATE_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          duplicateStatus: { $set: SUCCESS },
        },
      );
    }
    case SUBSCRIPTIONS_DUPLICATE_SUBMIT_FAILED: {
      return update(
        state,
        { duplicateStatus: { $set: FAILED } },
      );
    }
    case SUBSCRIPTIONS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          loadCodeStatus: { $set: '' },
          submitStatus: { $set: '' },
          duplicateStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          statistic: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
