import ws from "../ws";
import getToken from "../getToken";

const PARAMS_GET_ASYNC_DATA_PROGRESS = 'PARAMS_GET_ASYNC_DATA_PROGRESS';
const PARAMS_GET_ASYNC_DATA_SUCCESS = 'PARAMS_GET_ASYNC_DATA_SUCCESS';
const PARAMS_GET_ASYNC_DATA_FAILED = 'PARAMS_GET_ASYNC_DATA_FAILED';
const PARAMS_CLEAR_STATE = 'PARAMS_CLEAR_STATE';

const paramGetAsyncDataProgress = () => ({
  type: PARAMS_GET_ASYNC_DATA_PROGRESS,
});

const paramGetAsyncDataSuccess = payload => ({
  type: PARAMS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const paramGetAsyncDataFailed = () => ({
  type: PARAMS_GET_ASYNC_DATA_FAILED,
});

const paramClearState = () => ({
  type: PARAMS_CLEAR_STATE,
});

const transformAsyncData = data => ({
  landings: data.landings.map(landing => ({ value: landing.url, label: landing.name })),
  fields: data.fields.map(field => {
    const { amoId, enums } = field;
    return { ...field, id: amoId.toString(), enums: enums.map(item => ({ value: item.amoId, label: item.value })) };
  }),
});

const getAsyncData = () => dispatch => {
  const token = getToken();
  dispatch(paramGetAsyncDataProgress());

  ws.emit('api/app/params/getAsyncData', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(paramGetAsyncDataSuccess(transformAsyncData(payload)));
    } else {
      dispatch(paramGetAsyncDataFailed());
    }
  });
};

export {
  PARAMS_GET_ASYNC_DATA_PROGRESS,
  PARAMS_GET_ASYNC_DATA_SUCCESS,
  PARAMS_GET_ASYNC_DATA_FAILED,
  PARAMS_CLEAR_STATE,
  paramClearState,
  getAsyncData,
};
