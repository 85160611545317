import sideBar6 from '../assets/utils/images/sidebar/city1.jpg';
import jwtDecode from 'jwt-decode';
import { AUTH_AUTHENTICATED, AUTH_GUEST } from '../config/types';

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';

const THEME_OPTIONS = "themeOptions";

const saveOption = option => {
    const value = Object.keys(option);
    const optionsFromLocalStorage = localStorage.getItem(THEME_OPTIONS) || "{}";
    const options = JSON.parse(optionsFromLocalStorage);

    options[value[0]] = option[value[0]];
    localStorage.setItem(THEME_OPTIONS, JSON.stringify(options));
};

export const setEnableBackgroundImage = enableBackgroundImage => {
    saveOption({ enableBackgroundImage });

    return {
        type: SET_ENABLE_BACKGROUND_IMAGE,
        enableBackgroundImage,
    };
};

export const setEnableFixedHeader = enableFixedHeader => {
    saveOption({ enableFixedHeader });

    return {
        type: SET_ENABLE_FIXED_HEADER,
        enableFixedHeader,
    };
};

export const setEnableHeaderShadow = enableHeaderShadow => {
    saveOption({ enableHeaderShadow });

    return {
        type: SET_ENABLE_HEADER_SHADOW,
        enableHeaderShadow,
    };
};

export const setEnableSidebarShadow = enableSidebarShadow => {
    saveOption({ enableSidebarShadow });

    return {
        type: SET_ENABLE_SIDEBAR_SHADOW,
        enableSidebarShadow,
    };
};

export const setEnablePageTitleIcon = enablePageTitleIcon => {
    saveOption({ enablePageTitleIcon });

    return {
        type: SET_ENABLE_PAGETITLE_ICON,
        enablePageTitleIcon,
    };
};

export const setEnablePageTitleSubheading = enablePageTitleSubheading => {
    saveOption({ enablePageTitleSubheading });

    return {
        type: SET_ENABLE_PAGETITLE_SUBHEADING,
        enablePageTitleSubheading,
    };
};

export const setEnablePageTabsAlt = enablePageTabsAlt => {
    saveOption({ enablePageTabsAlt });

    return {
        type: SET_ENABLE_PAGE_TABS_ALT,
        enablePageTabsAlt,
    };
};

export const setEnableFixedSidebar = enableFixedSidebar => {
    saveOption({ enableFixedSidebar });

    return {
        type: SET_ENABLE_FIXED_SIDEBAR,
        enableFixedSidebar,
    };
};

export const setEnableClosedSidebar = enableClosedSidebar => {
    saveOption({ enableClosedSidebar });

    return {
        type: SET_ENABLE_CLOSED_SIDEBAR,
        enableClosedSidebar,
    };
};

export const setEnableMobileMenu = enableMobileMenu => {
    saveOption({ enableMobileMenu });

    return {
        type: SET_ENABLE_MOBILE_MENU,
        enableMobileMenu,
    };
};

export const setEnableMobileMenuSmall = enableMobileMenuSmall => {
    saveOption({ enableMobileMenuSmall });

    return {
        type: SET_ENABLE_MOBILE_MENU_SMALL,
        enableMobileMenuSmall,
    };
};

export const setEnableFixedFooter = enableFixedFooter => {
    saveOption({ enableFixedFooter });

    return {
        type: SET_ENABLE_FIXED_FOOTER,
        enableFixedFooter,
    };
};

export const setBackgroundColor = backgroundColor => {
    saveOption({ backgroundColor });

    return {
        type: SET_BACKGROUND_COLOR,
        backgroundColor,
    };
};

export const setHeaderBackgroundColor = headerBackgroundColor => {
    saveOption({ headerBackgroundColor });

    return {
        type: SET_HEADER_BACKGROUND_COLOR,
        headerBackgroundColor,
    };
};

export const setColorScheme = colorScheme => {
    saveOption({ colorScheme });

    return {
        type: SET_COLOR_SCHEME,
        colorScheme,
    };
};

export const setBackgroundImageOpacity = backgroundImageOpacity => {
    saveOption({ backgroundImageOpacity });

    return {
        type: SET_BACKGROUND_IMAGE_OPACITY,
        backgroundImageOpacity,
    };
};

export const setBackgroundImage = backgroundImage  => {
    saveOption({ backgroundImage });

    return {
        type: SET_BACKGROUND_IMAGE,
        backgroundImage,
    };
};

const getInitialState = () => {
    const optionsFromLocalStorage = localStorage.getItem(THEME_OPTIONS);
    const themeOptions = {
        backgroundColor: '',
        headerBackgroundColor: '',
        enableMobileMenuSmall: '',
        enableBackgroundImage: false,
        enableClosedSidebar: false,
        enableFixedHeader: true,
        enableHeaderShadow: true,
        enableSidebarShadow: true,
        enableFixedFooter: true,
        enableFixedSidebar: true,
        colorScheme: 'white',
        backgroundImage: sideBar6,
        backgroundImageOpacity: 'opacity-06',
        enablePageTitleIcon: true,
        enablePageTitleSubheading: true,
        enablePageTabsAlt: true,
    };

    if (optionsFromLocalStorage) {
        const options = JSON.parse(optionsFromLocalStorage);

        for (const [key, value] of Object.entries(options)) {
            themeOptions[key] = value;
        }
    }

    return themeOptions;
};

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case SET_ENABLE_BACKGROUND_IMAGE:
            return {
                ...state,
                enableBackgroundImage: action.enableBackgroundImage
            };

        case SET_ENABLE_FIXED_HEADER:
            return {
                ...state,
                enableFixedHeader: action.enableFixedHeader
            };

        case SET_ENABLE_HEADER_SHADOW:
            return {
                ...state,
                enableHeaderShadow: action.enableHeaderShadow
            };

        case SET_ENABLE_SIDEBAR_SHADOW:
            return {
                ...state,
                enableSidebarShadow: action.enableSidebarShadow
            };

        case SET_ENABLE_PAGETITLE_ICON:
            return {
                ...state,
                enablePageTitleIcon: action.enablePageTitleIcon
            };

        case SET_ENABLE_PAGETITLE_SUBHEADING:
            return {
                ...state,
                enablePageTitleSubheading: action.enablePageTitleSubheading
            };

        case SET_ENABLE_PAGE_TABS_ALT:
            return {
                ...state,
                enablePageTabsAlt: action.enablePageTabsAlt
            };

        case SET_ENABLE_FIXED_SIDEBAR:
            return {
                ...state,
                enableFixedSidebar: action.enableFixedSidebar
            };

        case SET_ENABLE_MOBILE_MENU:
            return {
                ...state,
                enableMobileMenu: action.enableMobileMenu
            };

        case SET_ENABLE_MOBILE_MENU_SMALL:
            return {
                ...state,
                enableMobileMenuSmall: action.enableMobileMenuSmall
            };

        case SET_ENABLE_CLOSED_SIDEBAR:
            return {
                ...state,
                enableClosedSidebar: action.enableClosedSidebar
            };

        case SET_ENABLE_FIXED_FOOTER:
            return {
                ...state,
                enableFixedFooter: action.enableFixedFooter
            };

        case SET_BACKGROUND_COLOR:
            return {
                ...state,
                backgroundColor: action.backgroundColor
            };

        case SET_HEADER_BACKGROUND_COLOR:
            return {
                ...state,
                headerBackgroundColor: action.headerBackgroundColor
            };

        case SET_COLOR_SCHEME:
            return {
                ...state,
                colorScheme: action.colorScheme
            };

        case SET_BACKGROUND_IMAGE:
            return {
                ...state,
                backgroundImage: action.backgroundImage
            };

        case SET_BACKGROUND_IMAGE_OPACITY:
            return {
                ...state,
                backgroundImageOpacity: action.backgroundImageOpacity
            };
            default:
    }
    return state;
}
