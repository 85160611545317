import ThemeOptions from "./ThemeOptions";
import auth from "./AuthReducer";
import users from "./UsersReducer";
import products from "./ProductsReducer";
import settings from "./SettingsReducer";
import analytics from "./AnalyticsReduces";
import subscriptions from "./SubscriptionsReducer";
import landings from "./LandingsReducer";
import templates from "./TemplatesReducer";
import params from "./ParamsReducer";
import merchants from "./MerchantsReducer";
import webinars from "./WebinarsReducer";
import keys from "./KeysReducer";
import bids from "./BidsReducer";
import recurrentPayments from "./RecurrentPaymentsReducer";
import wayForPayMerchants from "./WayForPayMerchantsReducer";

export default {
  ThemeOptions,
  auth,
  users,
  products,
  settings,
  analytics,
  subscriptions,
  landings,
  templates,
  params,
  merchants,
  webinars,
  keys,
  bids,
  recurrentPayments,
  wayForPayMerchants,
};
