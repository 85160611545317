import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";

const SUBSCRIPTIONS_GET_LIST_PROGRESS = 'SUBSCRIPTIONS_GET_LIST_PROGRESS';
const SUBSCRIPTIONS_GET_LIST_SUCCESS = 'SUBSCRIPTIONS_GET_LIST_SUCCESS';
const SUBSCRIPTIONS_GET_LIST_FAILED = 'SUBSCRIPTIONS_GET_LIST_FAILED';
const SUBSCRIPTIONS_FORM_SUBMIT_PROGRESS = 'SUBSCRIPTIONS_FORM_SUBMIT_PROGRESS';
const SUBSCRIPTIONS_FORM_SUBMIT_SUCCESS = 'SUBSCRIPTIONS_FORM_SUBMIT_SUCCESS';
const SUBSCRIPTIONS_FORM_SUBMIT_FAILED = 'SUBSCRIPTIONS_FORM_SUBMIT_FAILED';
const SUBSCRIPTIONS_GET_SUBSCRIPTION_PROGRESS = 'SUBSCRIPTIONS_GET_SUBSCRIPTION_PROGRESS';
const SUBSCRIPTIONS_GET_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS_GET_SUBSCRIPTION_SUCCESS';
const SUBSCRIPTIONS_GET_SUBSCRIPTION_FAILED = 'SUBSCRIPTIONS_GET_SUBSCRIPTION_FAILED';
const SUBSCRIPTIONS_GET_CODE_PROGRESS = 'SUBSCRIPTIONS_GET_CODE_PROGRESS';
const SUBSCRIPTIONS_GET_CODE_SUCCESS = 'SUBSCRIPTIONS_GET_CODE_SUCCESS';
const SUBSCRIPTIONS_GET_CODE_FAILED = 'SUBSCRIPTIONS_GET_CODE_FAILED';
const SUBSCRIPTIONS_GET_ASYNC_DATA_PROGRESS = 'SUBSCRIPTIONS_GET_ASYNC_DATA_PROGRESS';
const SUBSCRIPTIONS_GET_ASYNC_DATA_SUCCESS = 'SUBSCRIPTIONS_GET_ASYNC_DATA_SUCCESS';
const SUBSCRIPTIONS_GET_ASYNC_DATA_FAILED = 'SUBSCRIPTIONS_GET_ASYNC_DATA_FAILED';
const SUBSCRIPTIONS_DUPLICATE_SUBMIT_PROGRESS = 'SUBSCRIPTIONS_DUPLICATE_SUBMIT_PROGRESS';
const SUBSCRIPTIONS_DUPLICATE_SUBMIT_SUCCESS = 'SUBSCRIPTIONS_DUPLICATE_SUBMIT_SUCCESS';
const SUBSCRIPTIONS_DUPLICATE_SUBMIT_FAILED = 'SUBSCRIPTIONS_DUPLICATE_SUBMIT_FAILED';
const SUBSCRIPTIONS_CLEAR_STATE = 'SUBSCRIPTIONS_CLEAR_STATE';

const subscriptionGetListProgress = () => ({
  type: SUBSCRIPTIONS_GET_LIST_PROGRESS,
});

const subscriptionGetListSuccess = payload => ({
  type: SUBSCRIPTIONS_GET_LIST_SUCCESS,
  payload,
});

const subscriptionGetListFailed = () => ({
  type: SUBSCRIPTIONS_GET_LIST_FAILED,
});

const subscriptionStartFormSubmit = () => ({
  type: SUBSCRIPTIONS_FORM_SUBMIT_PROGRESS,
});

const subscriptionFormSubmitSuccess = () => ({
  type: SUBSCRIPTIONS_FORM_SUBMIT_SUCCESS,
});

const subscriptionFormSubmitFailed = () => ({
  type: SUBSCRIPTIONS_FORM_SUBMIT_FAILED,
});

const subscriptionGetFormProgress = () => ({
  type: SUBSCRIPTIONS_GET_SUBSCRIPTION_PROGRESS,
});

const subscriptionGetFormSuccess = payload => ({
  type: SUBSCRIPTIONS_GET_SUBSCRIPTION_SUCCESS,
  payload,
});

const subscriptionGetFormFailed = () => ({
  type: SUBSCRIPTIONS_GET_SUBSCRIPTION_FAILED,
});

const subscriptionGetCodeProgress = () => ({
  type: SUBSCRIPTIONS_GET_CODE_PROGRESS,
});

const subscriptionGetCodeSuccess = payload => ({
  type: SUBSCRIPTIONS_GET_CODE_SUCCESS,
  payload,
});

const subscriptionGetCodeFailed = () => ({
  type: SUBSCRIPTIONS_GET_CODE_FAILED,
});

const subscriptionGetAsyncDataProgress = () => ({
  type: SUBSCRIPTIONS_GET_ASYNC_DATA_PROGRESS,
});

const subscriptionGetAsyncDataSuccess = payload => ({
  type: SUBSCRIPTIONS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const subscriptionGetAsyncDataFailed = () => ({
  type: SUBSCRIPTIONS_GET_ASYNC_DATA_FAILED,
});

const subscriptionClearState = () => ({
  type: SUBSCRIPTIONS_CLEAR_STATE,
});

const subscriptionStartDuplicateSubmit = () => ({
  type: SUBSCRIPTIONS_DUPLICATE_SUBMIT_PROGRESS,
});

const subscriptionDuplicateSubmitSuccess = () => ({
  type: SUBSCRIPTIONS_DUPLICATE_SUBMIT_SUCCESS,
});

const subscriptionDuplicateSubmitFailed = () => ({
  type: SUBSCRIPTIONS_DUPLICATE_SUBMIT_FAILED,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    isArchive: doc.isArchive,
    updatedAt: moment(doc.updatedAt).format("LLL"),
  }));

  return { list, pagination };
};

const getSubscriptionsList = params => dispatch => {
  const token = getToken();
  dispatch(subscriptionGetListProgress());

  ws.emit('api/app/subscriptions/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(subscriptionGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(subscriptionGetListFailed());
    }
  });
};

const convertSetSubscriptionToDB = (data) => {
  const { name, product, isAddToBase, event, groups, redirect } = data;

  return {
    name,
    product: !product ? null : product.value,
    isAddToBase,
    event,
    groups: groups.map(group => group.value),
    code: {
      redirect,
    },
  };
};

const createSubscription = (data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetSubscriptionToDB(data);

  dispatch(subscriptionStartFormSubmit());

  ws.emit('api/app/subscriptions/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(subscriptionFormSubmitSuccess());
    } else {
      dispatch(subscriptionFormSubmitFailed());
    }
  });
};

const convertDBSubscriptionToEdit = payload => {
  const { subscription: subscriptionFromDB, product } = payload;
  const {
    name, isAddToBase, event, groups, created, modified, statistics, createdAt, updatedAt, isArchive, code,
  } = subscriptionFromDB;
  const { redirect } = code;

  const subscription = {
    name,
    product: product === null ? null : { value: product.amoId, label: product.value },
    isAddToBase,
    event,
    groups: groups.map(group => ({ value: group._id, label: group.name })),
    redirect,
  };

  const statistic = {
    created: `${created.name} ${created.surname}`,
    createdAt: moment(createdAt).format("LLL"),
    updated: `${modified.name} ${modified.surname}`,
    updatedAt: moment(updatedAt).format("LLL"),
    isArchive,
    subscriptions: statistics.subscriptions,
  };

  return { subscription, statistic };
};

const getSubscription = subscriptionId => dispatch => {
  const token = getToken();
  dispatch(subscriptionGetFormProgress());

  ws.emit('api/app/subscriptions/getById', { token, payload: { subscriptionId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(subscriptionGetFormSuccess(convertDBSubscriptionToEdit(payload)));
    } else {
      dispatch(subscriptionGetFormFailed());
    }
  });
};

const updateSubscription = (subscriptionId, data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetSubscriptionToDB(data);

  dispatch(subscriptionStartFormSubmit());

  ws.emit('api/app/subscriptions/updateById', { token, payload: { subscriptionId, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(subscriptionFormSubmitSuccess());
    } else {
      dispatch(subscriptionFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  groups: data.groups.map(group => ({ value: group.id, label: group.name })),
  products: data.products.enums.map(product => ({ value: product.amoId, label: product.value })),
});

const getAsyncData = () => dispatch => {
  const token = getToken();
  dispatch(subscriptionGetAsyncDataProgress());

  ws.emit('api/app/subscriptions/getAsyncData', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(subscriptionGetAsyncDataSuccess(transformAsyncData(payload)));
    } else {
      dispatch(subscriptionGetAsyncDataFailed());
    }
  });
};

const getSubscriptionSettings = subscriptionId => dispatch => {
  const token = getToken();
  dispatch(subscriptionGetCodeProgress());

  ws.emit('api/app/subscriptions/getProductCodeSetting', { token, payload: { subscriptionId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(subscriptionGetCodeSuccess(payload));
    } else {
      dispatch(subscriptionGetCodeFailed());
    }
  });
};

const setSubscriptionSettings = (subscriptionId, data) => dispatch => {
  const token = getToken();
  dispatch(subscriptionStartFormSubmit());

  ws.emit('api/app/subscriptions/setProductCodeSetting', { token, payload: { subscriptionId, data } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(subscriptionFormSubmitSuccess());
    } else {
      dispatch(subscriptionFormSubmitFailed());
    }
  });
};

const createDuplicate = subscriptionId => dispatch => {
  const token = getToken();
  dispatch(subscriptionStartDuplicateSubmit());

  ws.emit('api/app/subscriptions/createDuplicate', { token, payload: { subscriptionId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(subscriptionDuplicateSubmitSuccess());
    } else {
      dispatch(subscriptionDuplicateSubmitFailed());
    }
  });
};

export {
  SUBSCRIPTIONS_GET_LIST_PROGRESS,
  SUBSCRIPTIONS_GET_LIST_SUCCESS,
  SUBSCRIPTIONS_GET_LIST_FAILED,
  SUBSCRIPTIONS_FORM_SUBMIT_PROGRESS,
  SUBSCRIPTIONS_FORM_SUBMIT_SUCCESS,
  SUBSCRIPTIONS_FORM_SUBMIT_FAILED,
  SUBSCRIPTIONS_GET_SUBSCRIPTION_PROGRESS,
  SUBSCRIPTIONS_GET_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_GET_SUBSCRIPTION_FAILED,
  SUBSCRIPTIONS_GET_CODE_PROGRESS,
  SUBSCRIPTIONS_GET_CODE_SUCCESS,
  SUBSCRIPTIONS_GET_CODE_FAILED,
  SUBSCRIPTIONS_GET_ASYNC_DATA_PROGRESS,
  SUBSCRIPTIONS_GET_ASYNC_DATA_SUCCESS,
  SUBSCRIPTIONS_GET_ASYNC_DATA_FAILED,
  SUBSCRIPTIONS_CLEAR_STATE,
  SUBSCRIPTIONS_DUPLICATE_SUBMIT_PROGRESS,
  SUBSCRIPTIONS_DUPLICATE_SUBMIT_SUCCESS,
  SUBSCRIPTIONS_DUPLICATE_SUBMIT_FAILED,
  subscriptionClearState,
  getSubscriptionsList,
  createSubscription,
  getSubscription,
  updateSubscription,
  getAsyncData,
  getSubscriptionSettings,
  setSubscriptionSettings,
  createDuplicate,
};
