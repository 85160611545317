import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

import LoginPage from "../../containers/Account/Login";

const Analytics = lazy(() => import("../../containers/Analytics"));
const Users = lazy(() => import("../../containers/Users"));
const Landings = lazy(() => import("../../containers/Landings"));
const Products = lazy(() => import("../../containers/Products"));
const Subscriptions = lazy(() => import("../../containers/Subscriptions"));
const Settings = lazy(() => import("../../containers/Settings"));
const Templates = lazy(() => import("../../containers/Templates"));
const Params = lazy(() => import("../../containers/Params"));
const Merchants = lazy(() => import("../../containers/Merchants"));
const Webinars = lazy(() => import("../../containers/Webinars"));
const Keys = lazy(() => import("../../containers/Keys"));
const Bids = lazy(() => import("../../containers/Bids"));
const RecurrentPayments = lazy(() => import("../../containers/RecurrentPayments"));
const WayForPayMerchants = lazy(() => import("../../containers/WayForPayMerchants"));

const AppMain = () => {
    return (
      <Fragment>

        {/* Analytics */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/analytics" component={Analytics}/>
        </Suspense>

        {/* Landings */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/landings" component={Landings}/>
        </Suspense>

        {/* Templates */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/templates" component={Templates}/>
        </Suspense>

        {/* Params */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/params" component={Params}/>
        </Suspense>

        {/* Products */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/products" component={Products}/>
        </Suspense>

        {/* Subscriptions */}

        <Suspense fallback={
          <div className="loader-container">
              <div className="loader-container-inner">
                  <div className="text-center">
                      <Loader type="ball-pulse-rise"/>
                  </div>
                  <h6 className="mt-5">
                      Подождите
                  </h6>
              </div>
          </div>
        }>
          <Route path="/subscriptions" component={Subscriptions}/>
        </Suspense>

        {/* Webinars */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/recurrent-payments" component={RecurrentPayments}/>
        </Suspense>

        {/* Webinars */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/webinars" component={Webinars}/>
        </Suspense>

        {/* Users */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/users" component={Users}/>
        </Suspense>

        {/* Settings */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/settings" component={Settings}/>
        </Suspense>

        {/* Merchants */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/merchants" component={Merchants}/>
        </Suspense>

        {/* WayForPayMerchants */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/wayforpay-merchants" component={WayForPayMerchants}/>
        </Suspense>

        {/* Keys */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/keys" component={Keys}/>
        </Suspense>

        {/* Account */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party"/>
              </div>
              <h6 className="mt-3">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/account" component={LoginPage}/>
        </Suspense>

        {/* Bids */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party"/>
              </div>
              <h6 className="mt-3">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/bids" component={Bids}/>
        </Suspense>

        <Route exact path="/" render={() => (
            <Redirect to="/analytics"/>
        )}/>

        <ToastContainer/>
      </Fragment>
    )
};

export default AppMain;
