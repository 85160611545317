import validUrl from "valid-url";

const FIELD_IS_REQUIRED = 'Это поле является обязательным';
const ENTER_AT_LEAST_TWO_CHARACTERS = 'Введите не менее 2-х символов';
const ENTER_AT_LEAST_THREE_CHARACTERS = 'Введите не менее 3-х символов';
const ENTER_AT_LEAST_FOUR_CHARACTERS = 'Введите не менее 4-х символов';
const ENTER_AT_LEAST_FIVE_CHARACTERS = 'Введите не менее 5-х символов';
const ENTERED_AN_INVALID_EMAIL = 'Вы ввели неверный E-mail адрес';
const EMAIL_ALREADY_EXISTS = 'Такой E-mail уже существует';
const PASSWORDS_DO_NOT_MATCH = 'Пароли не совпадают';
const VALUE_IS_NOT_NUMERIC = 'Значение в этом поле должно быть числовым';
const URL_IS_NOT_VALID = 'Введен неверный URL адрес';
const URL_ALREADY_EXISTS = 'Такой URL уже существует';

const passValidation = () =>({ invalid: false, message: "" });
const notPassValidation = message =>({ invalid: true, message });

const initValidFields = formFields => {
  const result = {};
  const keys = Object.keys(formFields);

  for (const key of keys) {
    result[key] = passValidation();
  }

  return result;
};

const isInvalid = props => {
  let isValid = false;
  const keys = Object.keys(props);

  for (const key of keys) {
    const { invalid } = props[key];

    if (invalid) {
      isValid = !isValid;
      break;
    }
  }

  return isValid;
};

const validateSelect = (value) => {
  if ((typeof value !== 'object' || value === null) || !value.hasOwnProperty('value')) {
    return notPassValidation(FIELD_IS_REQUIRED);
  }

  return passValidation();
};

const validateMultiSelect = (value) => {
  if (!Array.isArray(value) || !value.length) {
    return notPassValidation(FIELD_IS_REQUIRED);
  }

  return passValidation();
};

const textRequiredMinTwoLetters = (value) => {
  if (!value) {
    return notPassValidation(FIELD_IS_REQUIRED);
  } else if (value.length < 2) {
    return notPassValidation(ENTER_AT_LEAST_TWO_CHARACTERS);
  }

  return passValidation();
};

const textRequiredMinFiveLetters = (value) => {
  if (!value) {
    return notPassValidation(FIELD_IS_REQUIRED);
  } else if (value.length < 5) {
    return notPassValidation(ENTER_AT_LEAST_FIVE_CHARACTERS);
  }

  return passValidation();
};

const requiredNumeric = (value) => {
  if (!value) {
    return notPassValidation(FIELD_IS_REQUIRED);
  } else if (isNaN(value)) {
    return notPassValidation(VALUE_IS_NOT_NUMERIC);
  }

  return passValidation();
};

const notRequiredButNumeric = (value) => {
  if (value.length && isNaN(value)) {
    return notPassValidation(VALUE_IS_NOT_NUMERIC);
  }

  return passValidation();
};

const passwordDoNotMatch = (password1, password2) => {
  if (!password2) {
    return notPassValidation(FIELD_IS_REQUIRED);
  } else if (password1 !== password2) {
    return notPassValidation(PASSWORDS_DO_NOT_MATCH);
  }

  return passValidation();
};

const isValidUrl = (value) => {
  if (!value) {
    return notPassValidation(FIELD_IS_REQUIRED);
  } else if (!validUrl.isWebUri(value)) {
    return notPassValidation(URL_IS_NOT_VALID);
  }

  return passValidation();
};

export {
  passValidation,
  notPassValidation,
  initValidFields,
  isInvalid,
  textRequiredMinTwoLetters,
  textRequiredMinFiveLetters,
  validateSelect,
  validateMultiSelect,
  requiredNumeric,
  notRequiredButNumeric,
  passwordDoNotMatch,
  isValidUrl,
  FIELD_IS_REQUIRED,
  ENTER_AT_LEAST_TWO_CHARACTERS,
  ENTER_AT_LEAST_THREE_CHARACTERS,
  ENTER_AT_LEAST_FOUR_CHARACTERS,
  ENTER_AT_LEAST_FIVE_CHARACTERS,
  ENTERED_AN_INVALID_EMAIL,
  EMAIL_ALREADY_EXISTS,
  PASSWORDS_DO_NOT_MATCH,
  VALUE_IS_NOT_NUMERIC,
  URL_IS_NOT_VALID,
  URL_ALREADY_EXISTS,
};
