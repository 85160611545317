import ws from '../ws';
import getToken from '../getToken';
import moment from 'moment';

const ANALYTICS_GET_ANALYTIC_PROGRESS = 'ANALYTICS_GET_ANALYTIC_PROGRESS';
const ANALYTICS_GET_ANALYTIC_SUCCESS = 'ANALYTICS_GET_ANALYTIC_SUCCESS';
const ANALYTICS_GET_ANALYTIC_FAILED = 'ANALYTICS_GET_ANALYTIC_FAILED';
const ANALYTICS_CLEAR_STATE = 'ANALYTICS_CLEAR_STATE';

const analyticGetAnalyticProgress = () => ({
  type: ANALYTICS_GET_ANALYTIC_PROGRESS,
});

const analyticGetAnalyticSuccess = payload => ({
  type: ANALYTICS_GET_ANALYTIC_SUCCESS,
  payload,
});

const analyticGetAnalyticFailed = () => ({
  type: ANALYTICS_GET_ANALYTIC_FAILED,
});

const analyticClearState = () => ({
  type: ANALYTICS_CLEAR_STATE,
});

const convertDBAnalyticsToEdit = payload => {
  const { analytics: analyticsFromDB } = payload;
  const { today, yesterday, bids: rawBids } = analyticsFromDB;

  const analytics = {
    today,
    yesterday,
    bids: rawBids.map(bid => ({
      id: bid._id,
      date: moment(bid.updatedAt).format('LLL'),
      lead: bid.product.lead.name,
      name: bid.person.name,
      phone: bid.person.phone,
      email: bid.person.email,
      isPaid: bid.isPaid,
      leadId: bid.leadId,
    })),
  };

  return { analytics };
};

const getAnalytics = () => dispatch => {
  const token = getToken();
  dispatch(analyticGetAnalyticProgress());

  ws.emit('api/app/analytics/get', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(analyticGetAnalyticSuccess(convertDBAnalyticsToEdit(payload)));
    } else {
      dispatch(analyticGetAnalyticFailed());
    }
  });
};

export {
  ANALYTICS_GET_ANALYTIC_PROGRESS,
  ANALYTICS_GET_ANALYTIC_SUCCESS,
  ANALYTICS_GET_ANALYTIC_FAILED,
  ANALYTICS_CLEAR_STATE,
  analyticClearState,
  getAnalytics,
};
