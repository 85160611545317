import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  PARAMS_GET_ASYNC_DATA_PROGRESS,
  PARAMS_GET_ASYNC_DATA_SUCCESS,
  PARAMS_GET_ASYNC_DATA_FAILED,
  PARAMS_CLEAR_STATE,
} from '../actions/ParamsAction';

const initialState = {
  asyncStatus: '', // progress/success/failed
  asyncData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PARAMS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: PROGRESS },
        },
      );
    }
    case PARAMS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: SUCCESS },
        },
      );
    }
    case PARAMS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: FAILED },
        },
      );
    }
    case PARAMS_CLEAR_STATE: {
      return update(
        state,
        {
          asyncStatus: { $set: '' },
          asyncData: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
