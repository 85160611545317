import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import roles from "../config/roles";

const USERS_GET_LIST_PROGRESS = 'USERS_GET_LIST_PROGRESS';
const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
const USERS_GET_LIST_FAILED = 'USERS_GET_LIST_FAILED';
const USERS_FORM_SUBMIT_PROGRESS = 'USERS_FORM_SUBMIT_PROGRESS';
const USERS_FORM_SUBMIT_SUCCESS = 'USERS_FORM_SUBMIT_SUCCESS';
const USERS_FORM_SUBMIT_FAILED = 'USERS_FORM_SUBMIT_FAILED';
const USERS_GET_USER_PROGRESS = 'USERS_GET_USER_PROGRESS';
const USERS_GET_USER_SUCCESS = 'USERS_GET_USER_SUCCESS';
const USERS_GET_USER_FAILED = 'USERS_GET_USER_FAILED';
const USERS_CLEAR_STATE = 'USERS_CLEAR_STATE';

const userGetListProgress = () => ({
  type: USERS_GET_LIST_PROGRESS,
});

const userGetListSuccess = payload => ({
  type: USERS_GET_LIST_SUCCESS,
  payload,
});

const userGetListFailed = () => ({
  type: USERS_GET_LIST_FAILED,
});

const userStartFormSubmit = () => ({
  type: USERS_FORM_SUBMIT_PROGRESS,
});

const userFormSubmitSuccess = () => ({
  type: USERS_FORM_SUBMIT_SUCCESS,
});

const userFormSubmitFailed = () => ({
  type: USERS_FORM_SUBMIT_FAILED,
});

const userGetUserProgress = () => ({
  type: USERS_GET_USER_PROGRESS,
});

const userGetUserSuccess = payload => ({
  type: USERS_GET_USER_SUCCESS,
  payload,
});

const userGetUserFailed = () => ({
  type: USERS_GET_USER_FAILED,
});

const userClearState = () => ({
  type: USERS_CLEAR_STATE,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const getRole = userRoles => {
    for (const role of roles) {
      if (userRoles.indexOf(role.value) !== -1) {
        return role.label;
      }
    }

    return roles[2].label;
  };

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    surname: doc.surname,
    role: getRole(doc.roles),
    isArchive: doc.isArchive,
    createdAt: moment(doc.createdAt).format("LLL"),
  }));

  return { list, pagination };
};

const getUsersList = params => dispatch => {
  const token = getToken();
  dispatch(userGetListProgress());

  ws.emit('api/app/users/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(userGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(userGetListFailed());
    }
  });
};

const convertSetUserToDB = data => {
  const {
    name, surname, birthday, roles: userRoles, phone, email, password,
  } = data;

  const userData = {
    name,
    surname,
    birthday: birthday instanceof Date ? moment(birthday).startOf('day').toJSON() : null,
    phone: phone.length ? phone : null,
    email,
  }

  if (typeof password !== 'undefined') {
    userData.password = password;
  }

  if (typeof roles !== 'undefined') {
    userData.roles = userRoles.map(role => role.value);
  }

  return userData;
};

const createUser = data => dispatch => {
  const token = getToken();
  const convertedData = convertSetUserToDB(data);

  dispatch(userStartFormSubmit());

  ws.emit('api/app/users/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(userFormSubmitSuccess());
    } else {
      dispatch(userFormSubmitFailed());
    }
  });
};

const convertDBUserToEdit = (payload) => {
  const { user: userFromDB } = payload;
  const { name, surname, birthday, roles: userRoles, phone, email } = userFromDB;

  const user = {
    name,
    surname,
    birthday: birthday !== null ? new Date(birthday) : null,
    roles: userRoles.map(userRole => roles.find(role => role.value === userRole)),
    phone: phone !== null ? phone: "",
    email,
  };

  return { user };
};

const getUser = userId => async (dispatch) => {
  const token = getToken();
  dispatch(userGetUserProgress());

  ws.emit('api/app/users/getById', { token, payload: { userId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = userGetUserSuccess(convertDBUserToEdit(payload));
    } else {
      action = userGetUserFailed();
    }

    dispatch(action);
  });
};

const updateUser = (id, data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetUserToDB(data);

  dispatch(userStartFormSubmit());

  ws.emit('api/app/users/updateById', { token, payload: { userId: id, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(userFormSubmitSuccess());
    } else {
      dispatch(userFormSubmitFailed());
    }
  });
};

const convertSetUserPasswordToDB = data => {
  const { password } = data;

  return {
    password
  };
};

const updateUserPassword = (id, data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetUserPasswordToDB(data);
  console.log(convertedData);
  dispatch(userStartFormSubmit());

  ws.emit('api/app/users/updatePasswordById', { token, payload: { userId: id, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(userFormSubmitSuccess());
    } else {
      dispatch(userFormSubmitFailed());
    }
  });
};

export {
  USERS_GET_LIST_PROGRESS,
  USERS_GET_LIST_SUCCESS,
  USERS_GET_LIST_FAILED,
  USERS_FORM_SUBMIT_PROGRESS,
  USERS_FORM_SUBMIT_SUCCESS,
  USERS_FORM_SUBMIT_FAILED,
  USERS_GET_USER_PROGRESS,
  USERS_GET_USER_SUCCESS,
  USERS_GET_USER_FAILED,
  USERS_CLEAR_STATE,
  userClearState,
  getUsersList,
  createUser,
  getUser,
  updateUser,
  updateUserPassword,
};
