import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  PRODUCTS_GET_LIST_PROGRESS,
  PRODUCTS_GET_LIST_SUCCESS,
  PRODUCTS_GET_LIST_FAILED,
  PRODUCTS_FORM_SUBMIT_PROGRESS,
  PRODUCTS_FORM_SUBMIT_SUCCESS,
  PRODUCTS_FORM_SUBMIT_FAILED,
  PRODUCTS_GET_PRODUCT_PROGRESS,
  PRODUCTS_GET_PRODUCT_SUCCESS,
  PRODUCTS_GET_PRODUCT_FAILED,
  PRODUCTS_GET_CODE_PROGRESS,
  PRODUCTS_GET_CODE_SUCCESS,
  PRODUCTS_GET_CODE_FAILED,
  PRODUCTS_GET_ASYNC_DATA_PROGRESS,
  PRODUCTS_GET_ASYNC_DATA_SUCCESS,
  PRODUCTS_GET_ASYNC_DATA_FAILED,
  PRODUCTS_CLEAR_STATE,
  PRODUCTS_DUPLICATE_SUBMIT_PROGRESS,
  PRODUCTS_DUPLICATE_SUBMIT_SUCCESS,
  PRODUCTS_DUPLICATE_SUBMIT_FAILED,
} from '../actions/ProductsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  loadCodeStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  duplicateStatus: '', // progress/success/failed
  asyncStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  fields: [],
  statistic: {},
  landings: [],
  asyncData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: PROGRESS },
        },
      );
    }
    case PRODUCTS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: SUCCESS },
        },
      );
    }
    case PRODUCTS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAILED },
        },
      );
    }
    case PRODUCTS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: PROGRESS } },
      );
    }
    case PRODUCTS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          submitStatus: { $set: SUCCESS },
        },
      );
    }
    case PRODUCTS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: FAILED } },
      );
    }
    case PRODUCTS_GET_PRODUCT_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          fields: { $set: [] },
          statistic: { $set: {} },
          landings: { $set: [] },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case PRODUCTS_GET_PRODUCT_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.product },
          fields: { $set: action.payload.fields || [] },
          statistic: { $set: action.payload.statistic || {} },
          landings: { $set: action.payload.landings || [] },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case PRODUCTS_GET_PRODUCT_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          fields: { $set: [] },
          statistic: { $set: {} },
          landings: { $set: [] },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case PRODUCTS_GET_CODE_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          fields: { $set: [] },
          statistic: { $set: {} },
          landings: { $set: [] },
          loadCodeStatus: { $set: PROGRESS },
        },
      );
    }
    case PRODUCTS_GET_CODE_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.product },
          fields: { $set: action.payload.fields || [] },
          statistic: { $set: action.payload.statistic || {} },
          landings: { $set: action.payload.landings || [] },
          loadCodeStatus: { $set: SUCCESS },
        },
      );
    }
    case PRODUCTS_GET_CODE_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          fields: { $set: [] },
          statistic: { $set: {} },
          landings: { $set: [] },
          loadCodeStatus: { $set: FAILED },
        },
      );
    }
    case PRODUCTS_GET_ASYNC_DATA_PROGRESS: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: PROGRESS },
        },
      );
    }
    case PRODUCTS_GET_ASYNC_DATA_SUCCESS: {
      return update(
        state, {
          asyncData: { $set: action.payload },
          asyncStatus: { $set: SUCCESS },
        },
      );
    }
    case PRODUCTS_GET_ASYNC_DATA_FAILED: {
      return update(
        state, {
          asyncData: { $set: {} },
          asyncStatus: { $set: FAILED },
        },
      );
    }
    case PRODUCTS_DUPLICATE_SUBMIT_PROGRESS: {
      return update(
        state,
        { duplicateStatus: { $set: PROGRESS } },
      );
    }
    case PRODUCTS_DUPLICATE_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          duplicateStatus: { $set: SUCCESS },
        },
      );
    }
    case PRODUCTS_DUPLICATE_SUBMIT_FAILED: {
      return update(
        state,
        { duplicateStatus: { $set: FAILED } },
      );
    }
    case PRODUCTS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          loadCodeStatus: { $set: '' },
          submitStatus: { $set: '' },
          duplicateStatus: { $set: '' },
          asyncStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          fields: { $set: [] },
          statistic: { $set: {} },
          landings: { $set: [] },
          asyncData: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
