const WEBINAR_NEW = 'new';
const WEBINAR_PROCESSED = 'processed';
const WEBINAR_FINISHED = 'finished';
const WEBINAR_FINISHED_WITH_DEF_DATE = 'finished_with_def_date';
const WEBINAR_FORWARDED = 'forwarded';
const WEBINAR_COMPLETED = 'completed';

export {
  WEBINAR_NEW,
  WEBINAR_PROCESSED,
  WEBINAR_FINISHED,
  WEBINAR_FINISHED_WITH_DEF_DATE,
  WEBINAR_FORWARDED,
  WEBINAR_COMPLETED,
};
