import jwtDecode from "jwt-decode";
import ws from "../ws";
import getToken from "../getToken";
import {
  AUTH_PROFILE_PROGRESS,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAILED,
  AUTH_TOKEN_RENEW_PROGRESS,
  AUTH_TOKEN_RENEW_SUCCESS,
  AUTH_TOKEN_RENEW_FAILED,
  AUTH_SING_IN_PROGRESS,
  AUTH_SING_IN_SUCCESS,
  AUTH_SING_IN_FAILED,
  AUTH_LOGOUT,
} from "../config/types";

const authStartFormSubmit = () => ({
  type: AUTH_SING_IN_PROGRESS,
});

const authFormSubmitSuccess = payload => ({
  type: AUTH_SING_IN_SUCCESS,
  payload,
});

const authFormSubmitFailed = () => ({
  type: AUTH_SING_IN_FAILED,
});

const authStartProfileSubmit = () => ({
  type: AUTH_PROFILE_PROGRESS,
});

const authProfileSubmitSuccess = payload => ({
  type: AUTH_PROFILE_SUCCESS,
  payload,
});

const authProfileSubmitFailed = () => ({
  type: AUTH_PROFILE_FAILED,
});

const authStartTokenRenewSubmit = () => ({
  type: AUTH_TOKEN_RENEW_PROGRESS,
});

const authTokenRenewSubmitSuccess = payload => ({
  type: AUTH_TOKEN_RENEW_SUCCESS,
  payload,
});

const authTokenRenewSubmitFailed = () => ({
  type: AUTH_TOKEN_RENEW_FAILED,
});

const authLogOut = () => ({
  type: AUTH_LOGOUT,
});

const convertSetAuthToDB = data => ({
  email: data.email,
  password: data.password,
});

const getExpiresInDate = payload => {
  const { auth } = payload;
  const { tokens } = auth;

  const accessToken = jwtDecode(tokens.accessToken);
  const refreshToken = jwtDecode(tokens.refreshToken);
  return { ...payload, accessExp: accessToken.exp * 1000, refreshExp: refreshToken.exp * 1000 };
};

const singIn = data => dispatch => {
  const convertedData = convertSetAuthToDB(data);

  dispatch(authStartFormSubmit());

  ws.emit('api/app/auth/singIn', { payload: convertedData, as: 'admin' }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(authFormSubmitSuccess(getExpiresInDate(payload)));
    } else {
      dispatch(authFormSubmitFailed());
    }
  });
};

const getUserProfile = () => dispatch => {
  const token = getToken();

  dispatch(authStartProfileSubmit);

  ws.emit('api/app/users/getUserProfile', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(authProfileSubmitSuccess(payload));
    } else {
      dispatch(authProfileSubmitFailed());
    }
  });
};

export {
  authStartFormSubmit,
  authFormSubmitSuccess,
  authFormSubmitFailed,
  authLogOut,
  getExpiresInDate,
  singIn,
  getUserProfile,
  authStartTokenRenewSubmit,
  authTokenRenewSubmitSuccess,
  authTokenRenewSubmitFailed,
};
