import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";

const TEMPLATES_GET_LIST_PROGRESS = 'TEMPLATES_GET_LIST_PROGRESS';
const TEMPLATES_GET_LIST_SUCCESS = 'TEMPLATES_GET_LIST_SUCCESS';
const TEMPLATES_GET_LIST_FAILED = 'TEMPLATES_GET_LIST_FAILED';
const TEMPLATES_FORM_SUBMIT_PROGRESS = 'TEMPLATES_FORM_SUBMIT_PROGRESS';
const TEMPLATES_FORM_SUBMIT_SUCCESS = 'TEMPLATES_FORM_SUBMIT_SUCCESS';
const TEMPLATES_FORM_SUBMIT_FAILED = 'TEMPLATES_FORM_SUBMIT_FAILED';
const TEMPLATES_GET_TEMPLATE_PROGRESS = 'TEMPLATES_GET_TEMPLATE_PROGRESS';
const TEMPLATES_GET_TEMPLATE_SUCCESS = 'TEMPLATES_GET_TEMPLATE_SUCCESS';
const TEMPLATES_GET_TEMPLATE_FAILED = 'TEMPLATES_GET_TEMPLATE_FAILED';
const TEMPLATES_CLEAR_STATE = 'TEMPLATES_CLEAR_STATE';

const templateGetListProgress = () => ({
  type: TEMPLATES_GET_LIST_PROGRESS,
});

const templateGetListSuccess = payload => ({
  type: TEMPLATES_GET_LIST_SUCCESS,
  payload,
});

const templateGetListFailed = () => ({
  type: TEMPLATES_GET_LIST_FAILED,
});

const templateStartFormSubmit = () => ({
  type: TEMPLATES_FORM_SUBMIT_PROGRESS,
});

const templateFormSubmitSuccess = () => ({
  type: TEMPLATES_FORM_SUBMIT_SUCCESS,
});

const templateFormSubmitFailed = () => ({
  type: TEMPLATES_FORM_SUBMIT_FAILED,
});

const templateGetTemplateProgress = () => ({
  type: TEMPLATES_GET_TEMPLATE_PROGRESS,
});

const templateGetTemplateSuccess = payload => ({
  type: TEMPLATES_GET_TEMPLATE_SUCCESS,
  payload,
});

const templateGetTemplateFailed = () => ({
  type: TEMPLATES_GET_TEMPLATE_FAILED,
});

const templateClearState = () => ({
  type: TEMPLATES_CLEAR_STATE,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    folder: doc.folder,
    createdAt: moment(doc.createdAt).format("LLL"),
  }));

  return { list, pagination };
};

const getTemplatesList = params => dispatch => {
  const token = getToken();
  dispatch(templateGetListProgress());

  ws.emit('api/app/templates/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(templateGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(templateGetListFailed());
    }
  });
};

const getTemplate = templateId => dispatch => {
  const token = getToken();
  dispatch(templateGetTemplateProgress());

  ws.emit('api/app/templates/getById', { token, payload: { templateId } }, (result) => {
    const { status, payload } = result;
    let action;

    if (status === 'ok') {
      action = templateGetTemplateSuccess(payload);
    } else {
      action = templateGetTemplateFailed();
    }

    dispatch(action);
  });
};

const updateTemplate = (id, data) => dispatch => {
  const token = getToken();
  dispatch(templateStartFormSubmit());

  ws.emit('api/app/templates/updateById', { token, payload: { templateId: id, data } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(templateFormSubmitSuccess());
    } else {
      dispatch(templateFormSubmitFailed());
    }
  });
};

export {
  TEMPLATES_GET_LIST_PROGRESS,
  TEMPLATES_GET_LIST_SUCCESS,
  TEMPLATES_GET_LIST_FAILED,
  TEMPLATES_FORM_SUBMIT_PROGRESS,
  TEMPLATES_FORM_SUBMIT_SUCCESS,
  TEMPLATES_FORM_SUBMIT_FAILED,
  TEMPLATES_GET_TEMPLATE_PROGRESS,
  TEMPLATES_GET_TEMPLATE_SUCCESS,
  TEMPLATES_GET_TEMPLATE_FAILED,
  TEMPLATES_CLEAR_STATE,
  templateClearState,
  getTemplatesList,
  getTemplate,
  updateTemplate,
};
