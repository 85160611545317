let apiUrl;
let frontUrl;
const { protocol, hostname } = window.location;

if (hostname === 'localhost') {
  apiUrl = `${protocol}//${hostname}:3500`;
  frontUrl = 'http://localhost:4500'
} else if (hostname === 'admin.karpachoff.com') {
  apiUrl = 'https://api.karpachoff.com';
  frontUrl = 'https://online.karpachoff.com'
} else {
  apiUrl = 'https://api.karpachoff.com';
  frontUrl = 'https://online.karpachoff.com'
}

const config = {
  urls: {
    api: apiUrl,
    front: frontUrl,
    amoFront: 'https://karpachoff.pipedrive.com',
  },
};

export default config;
