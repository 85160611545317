import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";
import { PRODUCT, SUBSCRIPTION } from "../config/forms";
import { GROUP_API, GROUP_MAIN, GROUP_TECHNICAL } from "../config/groups";
import {
  LOCATION_NAME, LOCATION_PHONE, LOCATION_EMAIL, LOCATION_BUTTON,
} from "../config/locations";

const LANDINGS_GET_LIST_PROGRESS = 'LANDINGS_GET_LIST_PROGRESS';
const LANDINGS_GET_LIST_SUCCESS = 'LANDINGS_GET_LIST_SUCCESS';
const LANDINGS_GET_LIST_FAILED = 'LANDINGS_GET_LIST_FAILED';
const LANDINGS_FORM_SUBMIT_PROGRESS = 'LANDINGS_FORM_SUBMIT_PROGRESS';
const LANDINGS_FORM_SUBMIT_SUCCESS = 'LANDINGS_FORM_SUBMIT_SUCCESS';
const LANDINGS_FORM_SUBMIT_FAILED = 'LANDINGS_FORM_SUBMIT_FAILED';
const LANDINGS_GET_LANDING_PROGRESS = 'LANDINGS_GET_LANDING_PROGRESS';
const LANDINGS_GET_LANDING_SUCCESS = 'LANDINGS_GET_LANDING_SUCCESS';
const LANDINGS_GET_LANDING_FAILED = 'LANDINGS_GET_LANDING_FAILED';
const LANDINGS_GET_ASYNC_DATA_PROGRESS = 'LANDINGS_GET_ASYNC_DATA_PROGRESS';
const LANDINGS_GET_ASYNC_DATA_SUCCESS = 'LANDINGS_GET_ASYNC_DATA_SUCCESS';
const LANDINGS_GET_ASYNC_DATA_FAILED = 'LANDINGS_GET_ASYNC_DATA_FAILED';
const LANDINGS_DUPLICATE_SUBMIT_PROGRESS = 'LANDINGS_DUPLICATE_SUBMIT_PROGRESS';
const LANDINGS_DUPLICATE_SUBMIT_SUCCESS = 'LANDINGS_DUPLICATE_SUBMIT_SUCCESS';
const LANDINGS_DUPLICATE_SUBMIT_FAILED = 'LANDINGS_DUPLICATE_SUBMIT_FAILED';
const LANDINGS_GET_FORM_DATA_PROGRESS = 'LANDINGS_GET_FORM_DATA_PROGRESS';
const LANDINGS_GET_FORM_DATA_SUCCESS = 'LANDINGS_GET_FORM_DATA_SUCCESS';
const LANDINGS_GET_FORM_DATA_FAILED = 'LANDINGS_GET_FORM_DATA_FAILED';
const LANDINGS_GET_GLOBALS_PROGRESS = 'LANDINGS_GET_GLOBALS_PROGRESS';
const LANDINGS_GET_GLOBALS_SUCCESS = 'LANDINGS_GET_GLOBALS_SUCCESS';
const LANDINGS_GET_GLOBALS_FAILED = 'LANDINGS_GET_GLOBALS_FAILED';
const LANDINGS_CLEAR_STATE = 'LANDINGS_CLEAR_STATE';

const PATH_CODE = "code";
const PATH_HTML = "html";

const prepareUrl = (url) => {
  let newUrl = url.replace(/\s+/g, '');

  if (newUrl.charAt(0) === '/') {
    newUrl = newUrl.substr(1);
  }

  if (newUrl.charAt(newUrl.length - 1) === '/') {
    newUrl = newUrl.substr(0, newUrl.length - 1);
  }

  return newUrl.toLowerCase();
};

const landingGetListProgress = () => ({
  type: LANDINGS_GET_LIST_PROGRESS,
});

const landingGetListSuccess = payload => ({
  type: LANDINGS_GET_LIST_SUCCESS,
  payload,
});

const landingGetListFailed = () => ({
  type: LANDINGS_GET_LIST_FAILED,
});

const landingStartFormSubmit = () => ({
  type: LANDINGS_FORM_SUBMIT_PROGRESS,
});

const landingFormSubmitSuccess = () => ({
  type: LANDINGS_FORM_SUBMIT_SUCCESS,
});

const landingFormSubmitFailed = () => ({
  type: LANDINGS_FORM_SUBMIT_FAILED,
});

const landingGetFormProgress = () => ({
  type: LANDINGS_GET_LANDING_PROGRESS,
});

const landingGetFormSuccess = payload => ({
  type: LANDINGS_GET_LANDING_SUCCESS,
  payload,
});

const landingGetFormFailed = () => ({
  type: LANDINGS_GET_LANDING_FAILED,
});

const landingGetAsyncDataProgress = () => ({
  type: LANDINGS_GET_ASYNC_DATA_PROGRESS,
});

const landingGetAsyncDataSuccess = payload => ({
  type: LANDINGS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const landingGetAsyncDataFailed = () => ({
  type: LANDINGS_GET_ASYNC_DATA_FAILED,
});

const landingClearState = () => ({
  type: LANDINGS_CLEAR_STATE,
});

const landingStartDuplicateSubmit = () => ({
  type: LANDINGS_DUPLICATE_SUBMIT_PROGRESS,
});

const landingDuplicateSubmitSuccess = () => ({
  type: LANDINGS_DUPLICATE_SUBMIT_SUCCESS,
});

const landingDuplicateSubmitFailed = () => ({
  type: LANDINGS_DUPLICATE_SUBMIT_FAILED,
});

const landingGetFormDataProgress = () => ({
  type: LANDINGS_GET_FORM_DATA_PROGRESS,
});

const landingGetFormDataSuccess = payload => ({
  type: LANDINGS_GET_FORM_DATA_SUCCESS,
  payload,
});

const landingGetFormDataFailed = () => ({
  type: LANDINGS_GET_FORM_DATA_FAILED,
});

const landingGetGlobalsProgress = () => ({
  type: LANDINGS_GET_GLOBALS_PROGRESS,
});

const landingGetGlobalsSuccess = payload => ({
  type: LANDINGS_GET_GLOBALS_SUCCESS,
  payload,
});

const landingGetGlobalsFailed = () => ({
  type: LANDINGS_GET_GLOBALS_FAILED,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    url: doc.url,
    template: doc.template ? doc.template.name : null,
    status: doc.condition.status,
    isArchive: doc.isArchive,
    updatedAt: moment(doc.updatedAt).format("LLL"),
  }));

  return { list, pagination };
};

const getLandingsList = params => dispatch => {
  const token = getToken();
  dispatch(landingGetListProgress());

  ws.emit('api/app/landings/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(landingGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(landingGetListFailed());
    }
  });
};

const convertSetLandingToDB = (dirtyForm, dirtyForms, variables, dirtyLocals, dirtyUrl, isPreview = false) => {
  const forms = [];
  const {
    name, template, status, redirect,
  } = dirtyForm;
  const randomUrl = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  const url = !isPreview ? prepareUrl(dirtyUrl) : randomUrl();
  const locals = dirtyLocals;

  for (let i = 0; i < dirtyForms.length; i += 1) {
    const form = JSON.parse(JSON.stringify(dirtyForms[i]));
    const { additionally } = form;
    const additionallyArray = form.type === PRODUCT ? additionally.products : additionally.subscriptions;

    if (additionallyArray.length) {
      const newAdditionallyArray = [];

      for (let i = 0; i < additionallyArray.length; i += 1) {
        newAdditionallyArray.push({
          [form.type === PRODUCT ? PRODUCT : SUBSCRIPTION]: additionallyArray[i][form.type === PRODUCT ? PRODUCT : SUBSCRIPTION].value,
          buttonText: additionallyArray[i].buttonText ? additionallyArray[i].buttonText : null,
          classes: additionallyArray[i].classes ? additionallyArray[i].classes : null,
        });
      }

      additionally[form.type === PRODUCT ? 'products' : 'subscriptions'] = newAdditionallyArray;
      form.additionally = additionally;
    }

    delete form.name;
    delete form.collapse;
    delete form.tab;
    delete form.original;

    forms.push(form);
  }

  const localsKeys = Object.keys(locals);

  for (let i = 0; i < localsKeys.length; i += 1) {
    if (!locals[localsKeys[i]].value) {
      locals[localsKeys[i]].value = null;
    }
  }

  return {
    name: !isPreview ? name : 'preview',
    url,
    template: template.value,
    condition: {
      status,
      redirect: redirect ? redirect : null,
    },
    forms,
    variables: variables.map((variable) => ({
      name: variable.name,
      value: variable.value ? variable.value : null,
    })),
    locals,
  };
};

const createLanding = (form, forms, variables, locals, url) => dispatch => {
  const token = getToken();
  const convertedData = convertSetLandingToDB(form, forms, variables, locals, url);

  dispatch(landingStartFormSubmit());

  ws.emit('api/app/landings/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(landingFormSubmitSuccess());
    } else {
      dispatch(landingFormSubmitFailed());
    }
  });
};

const convertDBLandingToEdit = payload => {
  const { landing: landingFromDB } = payload;
  const {
    name, url, template, condition, forms: dirtyForms, variables, locals, isArchive, created, modified, createdAt, updatedAt,
  } = landingFromDB;
  const getValueForSelect = data => ({ value: data._id, label: data.name });
  const forms = [];

  for (let i = 0; i < dirtyForms.length; i += 1) {
    const form = { ...dirtyForms[i] };
    let formName;
    let original;

    if (form.type === PRODUCT) {
      formName = form[PRODUCT].name;
      original = form[PRODUCT].code;
      form[PRODUCT] = form[PRODUCT]._id;
      form.additionally.products = form.additionally.products.map(item => ({
        ...item,
        [PRODUCT]: getValueForSelect(item[PRODUCT]),
      }));
    } else {
      formName = form[SUBSCRIPTION].name;
      original = form[SUBSCRIPTION].code;
      form[SUBSCRIPTION] = form[SUBSCRIPTION]._id;
      form.additionally.subscriptions = form.additionally.subscriptions.map(item => ({
        ...item,
        [SUBSCRIPTION]: getValueForSelect(item[SUBSCRIPTION]),
      }));
    }

    form.name = formName;
    form.collapse = false;
    form.tab = "1";
    form.original = original;
    forms.push(form);
  }

  const landing = {
    form: {
      name,
      template: template ? getValueForSelect(template) : null,
      status: condition.status,
      redirect: condition.redirect,
    },
    forms,
    url,
    variables,
    locals,
  };

  const statistic = {
    created: `${created.name} ${created.surname}`,
    createdAt: moment(createdAt).format("LLL"),
    updated: `${modified.name} ${modified.surname}`,
    updatedAt: moment(updatedAt).format("LLL"),
    isArchive,
  };

  return { landing, statistic };
};

const getLanding = landingId => dispatch => {
  const token = getToken();
  dispatch(landingGetFormProgress());

  ws.emit('api/app/landings/getById', { token, payload: { landingId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(landingGetFormSuccess(convertDBLandingToEdit(payload)));
    } else {
      dispatch(landingGetFormFailed());
    }
  });
};

const updateLanding = (landingId, form, forms, variables, locals, url) => dispatch => {
  const token = getToken();
  const convertedData = convertSetLandingToDB(form, forms, variables, locals, url);

  dispatch(landingStartFormSubmit());

  ws.emit('api/app/landings/updateById', { token, payload: { landingId, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(landingFormSubmitSuccess());
    } else {
      dispatch(landingFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  products: data.products.map(product => ({ value: product.id, label: product.name })),
  subscriptions: data.subscriptions.map(subscription => ({ value: subscription.id, label: subscription.name })),
  templates: data.templates.map(template => ({ value: template.id, label: template.name })),
});

const getAsyncData = () => dispatch => {
  const token = getToken();
  dispatch(landingGetAsyncDataProgress());

  ws.emit('api/app/landings/getAsyncData', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(landingGetAsyncDataSuccess(transformAsyncData(payload)));
    } else {
      dispatch(landingGetAsyncDataFailed());
    }
  });
};

const transformAsyncDataForTable = data => ({
  templates: data.templates.map(template => ({ value: template.id, label: template.name })),
});

const getAsyncDataForTable = () => dispatch => {
  const token = getToken();
  dispatch(landingGetAsyncDataProgress());

  ws.emit('api/app/landings/getAsyncDataForTable', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(landingGetAsyncDataSuccess(transformAsyncDataForTable(payload)));
    } else {
      dispatch(landingGetAsyncDataFailed());
    }
  });
};

const createDuplicate = landingId => dispatch => {
  const token = getToken();
  dispatch(landingStartDuplicateSubmit());

  ws.emit('api/app/landings/createDuplicate', { token, payload: { landingId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(landingDuplicateSubmitSuccess());
    } else {
      dispatch(landingDuplicateSubmitFailed());
    }
  });
};

const transformInfoData = data => {
  const { info } = data;

  if (info.hasOwnProperty('fields')) {
    const { fields: fieldsFromDb } = info;
    const groupApi = fieldsFromDb.filter(field => field.group === GROUP_API);
    const groupMain = fieldsFromDb.filter(field => field.group === GROUP_MAIN);
    const groupTechnical = fieldsFromDb.filter(field => field.group === GROUP_TECHNICAL);
    const fields = {};

    if (groupApi.length) {
      fields[GROUP_API] = groupApi;
    }

    if (groupMain.length) {
      fields[GROUP_MAIN] = groupMain;
    }

    if (groupTechnical.length) {
      fields[GROUP_TECHNICAL] = groupTechnical;
    }

    info.fields = fields;
  }

  return { info };
};

const getFormInfo = (formId, type) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/landings/getFormInfo', { token, payload: { formId, type } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      resolve(transformInfoData(payload));
    } else {
      reject();
    }
  });
});

const transformFormData = payload => {
  const { data } = payload;
  const { formId, name: formName, code, type } = data;
  const {
    name, id, classes, debug, fill, func, errors, messages, loading, cellular, lang, result, payment, redirect, cancel,
    labels, labelName, labelPhone, labelEmail, placeholderName, placeholderPhone, placeholderEmail,
    buttonText, resultHtml,
  } = code;

  const location = type === PRODUCT
    ? [LOCATION_NAME, LOCATION_PHONE, LOCATION_EMAIL, LOCATION_BUTTON]
    : [LOCATION_EMAIL, LOCATION_PHONE, LOCATION_BUTTON];

  const form = {
    name: formName,
    collapse: false,
    tab: "1",
    type,
    code: {
      name,
      id,
      classes,
      debug,
      fill,
      func,
      errors,
      messages,
      loading,
      cellular,
      lang,
      result,
      payment,
      redirect,
      cancel,
      labels,
      labelName,
      labelPhone,
      labelEmail,
      placeholderName,
      placeholderPhone,
      placeholderEmail,
      buttonText,
      resultHtml,
      use: {
        name: false,
        id: false,
        classes: false,
        debug: false,
        fill: false,
        func: false,
        errors: false,
        messages: false,
        loading: false,
        cellular: false,
        lang: false,
        result: false,
        payment: false,
        redirect: false,
        cancel: false,
        labels: false,
        labelName: false,
        labelPhone: false,
        labelEmail: false,
        placeholderName: false,
        placeholderPhone: false,
        placeholderEmail: false,
        buttonText: false,
        resultHtml: false,
      },
    },
    original: {
      name,
      id,
      classes,
      debug,
      fill,
      func,
      errors,
      messages,
      loading,
      cellular,
      lang,
      result,
      payment,
      redirect,
      cancel,
      labels,
      labelName,
      labelPhone,
      labelEmail,
      placeholderName,
      placeholderPhone,
      placeholderEmail,
      buttonText,
      resultHtml,
    },
    html: {
      place1: null,
      place2: null,
      place3: null,
      place4: null,
      place5: null,
      place6: null,
      place7: null,
    },
    location,
    additionally: {
      products: [],
      subscriptions: [],
    },
  };

  if (type === PRODUCT) {
    form.product = formId;
  } else {
    form.subscription = formId;
  }

  return { form };
};

const getFormData = (formId, type) => dispatch => {
  const token = getToken();
  dispatch(landingGetFormDataProgress());

  ws.emit('api/app/landings/getFormData', { token, payload: { formId, type } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(landingGetFormDataSuccess(transformFormData(payload)));
    } else {
      dispatch(landingGetFormDataFailed());
    }
  });
};

const getUrls = (query) => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/landings/getUrls', { token, payload: { query: prepareUrl(query) } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      resolve(payload.urls.map(item => ({ url: item.url })));
    } else {
      reject();
    }
  });
});

const createPreview = (form, forms, variables, locals, id = null) => new Promise((resolve, reject) => {
  const token = getToken();
  const convertedData = convertSetLandingToDB(form, forms, variables, locals, null, true);

  ws.emit('api/app/landings/preview', { token, payload: { landing: convertedData, id } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      const { id } = payload;
      resolve(id);
    } else {
      reject();
    }
  });
});

const getGlobals = () => dispatch => {
  const token = getToken();
  dispatch(landingGetGlobalsProgress());

  ws.emit('api/app/landings/getGlobals', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(landingGetGlobalsSuccess(payload));
    } else {
      dispatch(landingGetGlobalsFailed());
    }
  });
};

const convertSetGlobalsToDB = (dirtyGlobals, variables) => {
  const globals = { ...dirtyGlobals };
  const globalsKeys = Object.keys(globals);

  for (let i = 0; i < globalsKeys.length; i += 1) {
    if (!globals[globalsKeys[i]].value) {
      globals[globalsKeys[i]].value = null;
    }
  }

  return {
    globals,
    variables: variables.map((variable) => ({
      name: variable.name,
      value: variable.value ? variable.value : null,
    })),
  };
};

const updateGlobals = (globals, variables) => dispatch => {
  const token = getToken();
  const convertedData = convertSetGlobalsToDB(globals, variables);

  dispatch(landingStartFormSubmit());

  ws.emit('api/app/landings/updateGlobals', { token, payload: { data: convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(landingFormSubmitSuccess());
    } else {
      dispatch(landingFormSubmitFailed());
    }
  });
};

export {
  PATH_CODE,
  PATH_HTML,
  LANDINGS_GET_LIST_PROGRESS,
  LANDINGS_GET_LIST_SUCCESS,
  LANDINGS_GET_LIST_FAILED,
  LANDINGS_FORM_SUBMIT_PROGRESS,
  LANDINGS_FORM_SUBMIT_SUCCESS,
  LANDINGS_FORM_SUBMIT_FAILED,
  LANDINGS_GET_LANDING_PROGRESS,
  LANDINGS_GET_LANDING_SUCCESS,
  LANDINGS_GET_LANDING_FAILED,
  LANDINGS_GET_ASYNC_DATA_PROGRESS,
  LANDINGS_GET_ASYNC_DATA_SUCCESS,
  LANDINGS_GET_ASYNC_DATA_FAILED,
  LANDINGS_CLEAR_STATE,
  LANDINGS_DUPLICATE_SUBMIT_PROGRESS,
  LANDINGS_DUPLICATE_SUBMIT_SUCCESS,
  LANDINGS_DUPLICATE_SUBMIT_FAILED,
  LANDINGS_GET_FORM_DATA_PROGRESS,
  LANDINGS_GET_FORM_DATA_SUCCESS,
  LANDINGS_GET_FORM_DATA_FAILED,
  LANDINGS_GET_GLOBALS_PROGRESS,
  LANDINGS_GET_GLOBALS_SUCCESS,
  LANDINGS_GET_GLOBALS_FAILED,
  prepareUrl,
  landingClearState,
  getLandingsList,
  createLanding,
  getLanding,
  updateLanding,
  getAsyncData,
  getAsyncDataForTable,
  createDuplicate,
  getFormInfo,
  getFormData,
  getUrls,
  createPreview,
  getGlobals,
  updateGlobals,
};
