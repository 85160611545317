import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { Alert, Col, Row, Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FAILED, AUTH_AUTHENTICATED } from "../../../config/types";
import { initValidFields } from "../../../config/validate";
import { singIn as singInAction } from "../../../actions/AuthAction";
import validate from "./validate";

const LoginPage = props => {
  const formFields = {
    email: "",
    password: ""
  };

  const [form, setForm] = useState(formFields);
  const [formValidate, setFormValidate] = useState(initValidFields(formFields));

  const { submitStatus, authStatus } = props;

  if (authStatus === AUTH_AUTHENTICATED) {
    return <Redirect to="/analytics" />;
  }

  const handleChange = name => event => {
    setForm({ ...form, [name]: event.target.value });
  };

  const handleSubmit = () => {
    const { singIn } = props;
    const { isInvalid, result } = validate(form);

    setFormValidate({ ...result });

    if (!isInvalid) {
      singIn(form);
    }
  };

  return (
    <Fragment>
      <div className="h-100 bg-plum-plate bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <div className="app-logo-inverse mx-auto mb-3" />
            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="h5 modal-title text-center">
                    <h4 className="mt-2">
                      <div>Добро пожаловать,</div>
                      <span>Пожалуйста войдите в свою учетную запись.</span>
                    </h4>
                  </div>
                  {submitStatus === FAILED && (
                    <Alert color="danger">
                      Вы ввели неверный E-mail либо пароль!
                    </Alert>
                  )}
                  <Form>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange("email")}
                            placeholder="Ваш E-mail..."
                            invalid={formValidate.email.invalid}
                          />
                          <FormFeedback>
                            {formValidate.email.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="password"
                            name="password"
                            value={form.password}
                            onChange={handleChange("password")}
                            placeholder="Ваш пароль..."
                            invalid={formValidate.password.invalid}
                          />
                          <FormFeedback>
                            {formValidate.password.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="modal-footer clearfix">
                  <div className="float-left">
                    <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="btn-lg btn btn-link">
                      Восстановить пароль
                    </a>
                  </div>
                  <div className="float-right">
                    <Button color="primary" size="lg" onClick={handleSubmit}>
                      Войти
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

LoginPage.propTypes = {
  submitStatus: PropTypes.string.isRequired,
  authStatus: PropTypes.string.isRequired,
  singIn: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  submitStatus: state.auth.submitStatus,
  authStatus: state.auth.status
});

const mapDispatchToProps = dispatch => ({
  singIn: formData => dispatch(singInAction(formData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
