import update from "immutability-helper";
import jwtDecode from "jwt-decode";
import {
  PROGRESS,
  SUCCESS,
  FAILED,
  AUTH_PROCESSING,
  AUTH_AUTHENTICATED,
  AUTH_GUEST,
  AUTH_SING_IN_PROGRESS,
  AUTH_SING_IN_SUCCESS,
  AUTH_SING_IN_FAILED,
  AUTH_TOKEN_RENEW_PROGRESS,
  AUTH_TOKEN_RENEW_SUCCESS,
  AUTH_TOKEN_RENEW_FAILED,
  AUTH_PROFILE_PROGRESS,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAILED,
  AUTH_LOGOUT,
} from '../config/types';

const getInitialState = () => {
  try {
    const tokens = JSON.parse(localStorage.getItem('tokens')) || null;
    let accessExpiresIn = 0;
    let refreshExpiresIn = 0;

    if (tokens) {
      const accessToken = jwtDecode(tokens.accessToken);
      const refreshToken = jwtDecode(tokens.refreshToken);

      accessExpiresIn = accessToken.exp * 1000;
      refreshExpiresIn = refreshToken.exp * 1000;
    }

    return {
      tokens,
      profile: null,
      accessExp: accessExpiresIn,
      refreshExp: refreshExpiresIn,
      status: tokens ? AUTH_AUTHENTICATED : AUTH_GUEST,
      submitStatus: '', // progress/success/failed
      profileStatus: '', // progress/success/failed
      tokenRenewStatus: '', // progress/success/failed
    };
  } catch (err) {
    return {
      tokens: null,
      profile: null,
      accessExp: 0,
      refreshExp: 0,
      status: AUTH_GUEST,
      submitStatus: '', // progress/success/failed
      profileStatus: '', // progress/success/failed
      tokenRenewStatus: '', // progress/success/failed
    };
  }
};

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case AUTH_SING_IN_PROGRESS: {
      return update(
        state,
        {
          status: { $set: AUTH_PROCESSING },
          submitStatus: { $set: PROGRESS },
        },
      );
    }
    case AUTH_SING_IN_SUCCESS: {
      return update(
        state,
        {
          profile: { $set: action.payload.auth.profile },
          tokens: { $set: action.payload.auth.tokens },
          accessExp: { $set: action.payload.accessExp },
          refreshExp: { $set: action.payload.refreshExp },
          status: { $set: AUTH_AUTHENTICATED },
          submitStatus: { $set: SUCCESS },
        },
      );
    }
    case AUTH_SING_IN_FAILED: {
      return update(
        state,
        {
          profile: { $set: null },
          tokens: { $set: null },
          accessExp: { $set: 0 },
          refreshExp: { $set: 0 },
          status: { $set: AUTH_GUEST },
          submitStatus: { $set: FAILED },
        },
      );
    }
    case AUTH_PROFILE_PROGRESS: {
      return update(
        state,
        { profileStatus: { $set: PROGRESS } },
      );
    }
    case AUTH_PROFILE_SUCCESS: {
      return update(
        state,
        {
          profile: { $set: action.payload.profile },
          profileStatus: { $set: SUCCESS },
        },
      );
    }
    case AUTH_PROFILE_FAILED: {
      return update(
        state,
        {
          profile: { $set: null },
          tokens: { $set: null },
          accessExp: { $set: 0 },
          refreshExp: { $set: 0 },
          status: { $set: AUTH_GUEST },
          submitStatus: { $set: '' },
          profileStatus: { $set: FAILED },
          tokenRenewStatus: { $set: '' },
        },
      );
    }
    case AUTH_TOKEN_RENEW_PROGRESS: {
      return update(
        state,
        { tokenRenewStatus: { $set: PROGRESS } },
      );
    }
    case AUTH_TOKEN_RENEW_SUCCESS: {
      return update(
        state,
        {
          tokens: { $set: action.payload.auth.tokens },
          accessExp: { $set: action.payload.accessExp },
          refreshExp: { $set: action.payload.refreshExp },
          tokenRenewStatus: { $set: SUCCESS },
        },
      );
    }
    case AUTH_TOKEN_RENEW_FAILED: {
      return update(
        state,
        {
          profile: { $set: null },
          tokens: { $set: null },
          accessExp: { $set: 0 },
          refreshExp: { $set: 0 },
          status: { $set: AUTH_GUEST },
          submitStatus: { $set: '' },
          profileStatus: { $set: '' },
          tokenRenewStatus: { $set: FAILED },
        },
      );
    }
    case AUTH_LOGOUT: {
      return update(
        state,
        {
          profile: { $set: null },
          tokens: { $set: null },
          accessExp: { $set: 0 },
          refreshExp: { $set: 0 },
          status: { $set: AUTH_GUEST },
          submitStatus: { $set: '' },
          profileStatus: { $set: '' },
          tokenRenewStatus: { $set: '' },
        },
      );
    }
    default:
      return state;
  }
}
